<template>
    <div>
        <role-comp :type="type"></role-comp>

    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import RoleComp from "@/components/admin/role/RoleComp.vue";

export default defineComponent({
    name: "view-role",
    components : {RoleComp},

    setup() {
        const type = ref('view');
        onMounted(() => {
            setCurrentPageBreadcrumbs("View Role", ["Roles"],'create-role');
        });


        return {
            getIllustrationsPath,
            type,
        };
    },

});
</script>
